import React from 'react';

import { InboxOutlined, } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { notification, Upload } from 'antd';

import { useCrawlerContext } from '../../contexts/CrawlerContext';

const { Dragger } = Upload;

const UploadFile: React.FC = () => {
    const [notifyApi, contextHolder] = notification.useNotification();
    const { setFile } = useCrawlerContext();

    const props: UploadProps = {
        beforeUpload: (file) => {
            const isCsv = file.type === 'text/csv';
            if (!isCsv) {
                notifyApi.open({
                    message: 'Invalid file type',
                    description: `${file.name} is not a csv file`,
                    type: 'error',
                });
            }
            else {
                setFile(file);
            }
            return false;
        },
        name: 'uploadFile',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        className: 'upload-file',
    };

    return (
        <Dragger {...props}>
            {contextHolder}
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Click or drag file here to start
            </p>
            <p className="ant-upload-hint">
                Support .csv file only
            </p>
        </Dragger>
    )
};

export default UploadFile;