import React from "react";

import { Flex, Space, Button, Switch, InputNumber } from "antd";
import { ReloadOutlined, DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { useCrawlerContext } from "../../contexts/CrawlerContext";

interface ReportToolbarProps {
    loading: boolean;
    refresh: () => void;
    enableAutoRefresh: boolean;
    setEnableAutoRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    refreshInterval: number;
    setRefreshInterval: React.Dispatch<React.SetStateAction<number>>;
}

const ReportToolbar: React.FC<ReportToolbarProps> = ({ loading, refresh, enableAutoRefresh, setEnableAutoRefresh, refreshInterval, setRefreshInterval }) => {
    const { uploadResult, setUploadResult } = useCrawlerContext();

    return (
        <Flex vertical={false} justify="space-between" align="center">

            <Button type="text" onClick={() => { setUploadResult(undefined) }}>
                <ArrowLeftOutlined />
                Back
            </Button>

            <Space size="middle">
                <Space>
                    Auto refresh: <Switch title="autoRefresh" defaultChecked={enableAutoRefresh} onChange={setEnableAutoRefresh} />
                </Space>

                <Space>
                    Interval:
                    <InputNumber
                        style={{ width: "140px" }}
                        min={20}
                        max={300}
                        defaultValue={refreshInterval}
                        suffix="seconds"
                        changeOnWheel={true}
                        onChange={(value) => setRefreshInterval(value ?? 60)} />
                </Space>
            </Space>

            <Space>
                <Button
                    type="default"
                    onClick={refresh}
                    loading={loading}
                >
                    Refresh
                    <ReloadOutlined />
                </Button>

                <Button
                    type="default"
                    onClick={() => window.open(`${process.env.REACT_APP_API_URL}/reports/${uploadResult?.importId}/download`)}
                >
                    Download
                    <DownloadOutlined />
                </Button>
            </Space>

        </Flex>
    )
};

export default ReportToolbar;