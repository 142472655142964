import { useCrawlerContext } from '../../contexts/CrawlerContext';

import UploadFile from './UploadFile';
import FileDisplay from './FileDisplay';
import UploadResult from './UploadResult';

const Crawler: React.FC = () => {
    const { file, uploadResult } = useCrawlerContext();

    return (
        <>
        {}
            {!file && <UploadFile />}
            {(file && !uploadResult) && <FileDisplay />}
            {(file && uploadResult) && <UploadResult />}
        </>
    )
};

export default Crawler;