import React from 'react';

import { FileTextTwoTone, ArrowRightOutlined } from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { Button, Space, Card } from 'antd';

import { useCrawlerContext } from '../../contexts/CrawlerContext';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const FileDisplay: React.FC = () => {
    const { setFile, uploadMutation, file } = useCrawlerContext();

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('uploadFile', file as FileType);
        uploadMutation.mutate(formData);
    };

    return (
        <Space
            direction='vertical'
            align='center'
            size='large'
            className='file-display-con'
        >
            <Card className='file-display' onClick={() => setFile(undefined)}>
                <Space
                    direction='vertical'
                    size='small'
                    align='center'
                    className='file-con'
                >
                    <FileTextTwoTone className='file-icon' />
                    {file?.name}
                </Space>
            </Card>

            <Button
                type='primary'
                loading={uploadMutation.isPending}
                onClick={handleUpload}
            >
                Start crawl
                <ArrowRightOutlined />
            </Button>
        </Space>
    );
}

export default FileDisplay;