import React from 'react';

import { Typography, Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { DownloadOutlined, FileSearchOutlined } from '@ant-design/icons';

import { createStyles } from 'antd-style';

import Crawler from './Crawler/Crawler';
import Report from './Report/Report';

import { useCrawlerContext } from '../contexts/CrawlerContext';

const { Title } = Typography;
const { Header, Content } = Layout;

const useStyles = createStyles(({ token, css }) => ({
	header: css`
		color: ${token.colorPrimary} !important;
		font-weight: bold !important;
		margin: 0 !important;
	`
}));

const menuItems: MenuProps['items'] = [
	{
		key: '1',
		label: 'Crawler',
		icon: <DownloadOutlined />
	},
	{
		key: '2',
		label: 'Report',
		icon: <FileSearchOutlined />
	}
];

const App: React.FC = () => {
	const { selectedTab, setUploadResult, setSelectedTab } = useCrawlerContext();

	const { styles } = useStyles();

	return (
		<Layout className='layout'>
			<Header className='layout-header'>
				<Title
					className={styles.header}
					level={3}>
					Toxic Crawler
				</Title>

				<Menu
					className='header-menu'
					mode='horizontal'
					defaultSelectedKeys={[selectedTab]}
					selectedKeys={[selectedTab]}
					items={menuItems}
					onClick={(e) => {
						setSelectedTab(e.key)
						setUploadResult(undefined);
					}}
				/>
			</Header>

			<Content className='layout-content'>

				{selectedTab === '1' && <Crawler />}
				{selectedTab === '2' && <Report />}

			</Content>
		</Layout>
	)
};

export default App;