import { createRoot } from 'react-dom/client';

import App from './components/App';

import { ConfigProvider } from 'antd';
import { CrawlerProvider } from './contexts/CrawlerContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './index.css'

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<ConfigProvider
		theme={
			{
				token: {
					colorPrimary: '#00b96b',
					borderRadius: 4,
					colorBgContainer: '#ebebeb',
				}
			}
		}>
		<QueryClientProvider client={queryClient}>
			<CrawlerProvider>
				<App />
			</CrawlerProvider>
		</QueryClientProvider>
	</ConfigProvider>

);