import React from 'react';

import { ReloadOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Space, Statistic, Card, Row, Col, Typography } from 'antd';

import { useCrawlerContext } from '../../contexts/CrawlerContext';

const { Text } = Typography;

const UploadResult: React.FC = () => {
    const { uploadResult, setFile, setUploadResult, setSelectedTab } = useCrawlerContext();

    return (
        <Space
            direction='vertical'
            align='center'
            size='large'
            className='result-con'
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card bordered>
                        <Statistic title='Total product' value={uploadResult?.total} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered>
                        <Statistic title='Errors' value={uploadResult?.errors} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered>
                        <Statistic
                            title={
                                <Text
                                    copyable={{
                                        text: uploadResult?.importId,
                                    }}
                                >
                                    Import ID
                                </Text>
                            }
                            value={uploadResult?.importId}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered>
                        <Statistic title='Status' value='Running..' />
                    </Card>
                </Col>
            </Row>

            <Space>
                <Button
                    type='default'
                    onClick={() => {
                        setFile(undefined)
                        setUploadResult(undefined)
                    }}
                >
                    Crawl again
                    <ReloadOutlined />
                </Button>
                <Button
                    type='primary'
                    onClick={() => setSelectedTab('2')}
                >
                    View report
                    <ArrowRightOutlined />
                </Button>
            </Space>
        </Space>
    );
};

export default UploadResult;