import ApiClient from "./ApiClient";
import { BaseReport, ReportResponse } from "../interfaces/ReportResponse";
import { useQuery } from "@tanstack/react-query";

async function getReportById(importId: string | undefined): Promise<ReportResponse[]> {
    if (!importId) throw new Error("Import ID is required");

    const response = await ApiClient({
        method: "get",
        url: `/reports/${importId}`,
    });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    if (response.status === 200) {
        return response.data;
    }

    throw new Error("Get report failed");
}

export function useGetReportByIdQuery(importId: string | undefined, autoRefesh: boolean = true, interval: number = 60) {
    return useQuery<ReportResponse[]>({
        queryKey: ['report', importId],
        queryFn: () => getReportById(importId),
        enabled: !!importId,
        refetchInterval: autoRefesh ? interval * 1000 : false,
    });
}

async function getAllReports(): Promise<BaseReport[]> {
    const response = await ApiClient({
        method: "get",
        url: `/reports`,
    });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    if (response.status === 200) {
        return response.data;
    }

    throw new Error("Get all reports failed");
}

export function useGetAllReportsQuery(enabled: boolean = true) {
    return useQuery<BaseReport[]>({
        queryKey: ['all-reportr'],
        queryFn: () => getAllReports(),
        enabled: enabled,
    });
}