import React from "react";
import { notification } from "antd";
import { usePostFileMutaion } from "../apis/Upload";
import { UploadResponse } from "../interfaces/UploadResponse";
import { UseMutationResult } from '@tanstack/react-query';

import type { UploadFile } from 'antd';

interface CrawlerContextProps {
    selectedTab: string;
    setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
    uploadMutation: UseMutationResult<UploadResponse, Error, FormData, unknown>;
    uploadResult: UploadResponse | undefined;
    setUploadResult: React.Dispatch<React.SetStateAction<UploadResponse | undefined>>;
    file: UploadFile | undefined;
    setFile: React.Dispatch<React.SetStateAction<UploadFile | undefined>>;
}

const CrawlerContext = React.createContext<CrawlerContextProps>(null!);

export function CrawlerProvider({ children }: { children: React.ReactNode }) {
    const [selectedTab, setSelectedTab] = React.useState<string>('1');
    const [uploadResult, setUploadResult] = React.useState<UploadResponse>();
    const [file, setFile] = React.useState<UploadFile>();

    const [notifyApi, contextHolder] = notification.useNotification();

    const onUploadSuccess = (data: UploadResponse) => {
        setUploadResult(data);
    }

    const onUploadError = (error: Error) => {
        notifyApi.open({
            message: 'Upload file failed',
            description: error.message,
            type: 'error',
        });
    }

    const uploadMutation = usePostFileMutaion(onUploadSuccess, onUploadError);

    return (
        <CrawlerContext.Provider value={{
            selectedTab, setSelectedTab,
            uploadMutation, uploadResult, setUploadResult,
            file, setFile,
        }}>
            {contextHolder}
            {children}
        </CrawlerContext.Provider>
    )
}

export function useCrawlerContext() {
    return React.useContext(CrawlerContext);
}