import { useMutation } from "@tanstack/react-query";
import ApiClient from "./ApiClient";
import { UploadResponse } from "../interfaces/UploadResponse";

async function postFile(file: FormData): Promise<UploadResponse> {
    const response = await ApiClient({
        method: "post",
        url: "/upload",
        data: file,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
    await new Promise((resolve) => setTimeout(resolve, 2000));
    if (response.status === 200) {
        return response.data;
    }

    throw new Error("Upload failed");
}

export function usePostFileMutaion(onSuccess: (data: UploadResponse) => void, onError: (error: Error) => void) {
    return useMutation({
        mutationKey: ['upload'],
        mutationFn: (file: FormData) => postFile(file),
        onSuccess: onSuccess,
        onError: onError,
    })
}