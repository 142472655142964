import React from "react";

import { useGetAllReportsQuery } from "../../apis/Report";

import { Flex, Table, Badge, notification, Button, Typography, Input, Space } from "antd";
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import type { GetProp, TableProps, InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';

import { createStyles } from "antd-style";

import { BaseReport } from "../../interfaces/ReportResponse";
import { UploadResponse } from "../../interfaces/UploadResponse";

import { useCrawlerContext } from "../../contexts/CrawlerContext";

import moment from "moment";

import Highlighter from 'react-highlight-words';

import { AxiosError } from "axios";

const { Title } = Typography;

const useStyles = createStyles(({ token, css }) => ({
    header: css`
		color: ${token.colorPrimary} !important;
		font-weight: bold !important;
		margin: 0 !important;
	`,
    searchIcon: {
        color: token.colorPrimary,
    }
}));


type DataIndex = keyof BaseReport;

type ColumnsType<T extends object> = GetProp<TableProps<T>, 'columns'>;

const ListReport: React.FC = () => {
    const [notifyApi, contextHolder] = notification.useNotification();
    const { data, isError, error, refetch, isFetching } = useGetAllReportsQuery();
    const { setUploadResult } = useCrawlerContext();

    const [searchText, setSearchText] = React.useState('');
    const [searchedColumn, setSearchedColumn] = React.useState('');
    const searchInput = React.useRef<InputRef>(null);

    const { styles } = useStyles();

    const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: DataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm']) => {
        setSearchText('');
        clearFilters();
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<BaseReport> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <Space direction="vertical" style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search text`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="link"
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space>
            </Space>
        ),

        filterIcon: (filtered: boolean) => (
            <SearchOutlined className={filtered ? styles.searchIcon : undefined} />
        ),

        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes((value as string).toLowerCase()),

        filterDropdownProps: {
            onOpenChange(open) {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },

        render: (text) =>
            searchedColumn === dataIndex ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
                : text
    });

    React.useEffect(() => {
        if (isError) {
            const convert = error as AxiosError;
            notifyApi.open({
                message: 'Get report failed',
                description: convert.response?.data as string,
                type: 'error',
                placement: 'bottomRight',
            });
        }
    });

    const uniqueStatus = data?.reduce((acc: string[], obj: BaseReport) => {
        if (!acc.includes(obj.status)) {
            acc.push(obj.status);
        }
        return acc.sort();
    }, []);

    const columns: ColumnsType<BaseReport> = [
        {
            title: 'Import Id',
            dataIndex: 'importId',
            key: 'importId',
            fixed: 'left',
            width: 300,
            ...getColumnSearchProps('importId'),
        },
        {
            title: 'Total Products',
            dataIndex: 'count',
            key: 'count',
            fixed: 'left',
            width: 150
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            fixed: 'left',
            width: 200,
            filters: uniqueStatus?.map((status) => {
                return { text: status, value: status };
            }),
            render: (record: string) => {
                if (record.toLowerCase() === 'completed') {
                    return <Badge status='success' text={record} />
                }
                return <Badge status='warning' text={record} />
            },
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            fixed: 'left',
            width: 200,
            sorter: (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
            render: (record: Date) => moment(record).format('HH:mm:ss - DD/MM/yyyy'),
        }
    ];

    return (
        <Flex vertical={true} gap="middle" className="report-con">
            {contextHolder}

            <Flex vertical={false} justify="space-between" align="center">
                <Title
                    className={styles.header}
                    level={5}>
                    Report List
                </Title>
                <Button
                    type="default"
                    onClick={() => refetch()}
                    loading={isFetching}
                >
                    Refresh
                    <ReloadOutlined />
                </Button>
            </Flex>

            <Table<BaseReport>
                dataSource={data}
                columns={columns}
                loading={isFetching}
                bordered={true}
                pagination={{ pageSize: 20, showTotal: (total) => `Total ${total} items` }}
                rowKey="importId"
                scroll={{ y: 'calc(100vh - 250px)' }}
                onRow={(record) => {
                    return {
                        onClick: () => { setUploadResult(pre => ({ ...pre, importId: record.importId } as UploadResponse)) }
                    }
                }}
            />
        </Flex>

    )
};

export default ListReport;