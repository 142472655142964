import { useCrawlerContext } from "../../contexts/CrawlerContext";

import ReportDetailView from "./ReportDetailView";
import ListReport from "./ListReport";

const Report: React.FC = () => {
    const { uploadResult } = useCrawlerContext();

    return uploadResult?.importId ? (<ReportDetailView />) : (<ListReport />);
}

export default Report;